body[data-theme="dark"] {
  @import "../src/scss/dark-slider.scss";

  .slider-tab {
    background-color: #8c8c8c;
  }

  .title-styles {
    color: white;
  }

  #extra-section {
   color: red;
  }

  .header-overlay {
  
  }

  .header-icon {
    color: #3c342c;
    opacity: 0.8;
  }

  .profile-caption {
    color: white
  }

  .text-icon {
    color: rgb(223, 88, 88)
  }

  .slider-image {
    border: 5px solid #8c8c8c;
  }

  .language {
    background-color: #494949;
  }

  .link-href {
    color: white;
  }

  .project-date {
    background-color: #918e97;
    color: white;
  }

  header {
    background-color: #494949;
    h1 {
      color: white;
    }
  }

  #about {
    background-color: #7f7f7f;
    h1 span {
      color: white;
    }
    .polaroid span {
      background: #6d6d6d;
    }
    .card {
      background: #6d6d6d;
      color: white;
    }
  }

  #portfolio {
    background: #5b5b5b;
    .section-title {
      color: white !important;
    }
    .foto div {
      background: #6d6d6d;
    }
    .project-title-settings {
      color: white;
    }
  }

  #resume {
    filter: brightness(80%);
    background: #5b5b5b;
    .section-title {
      color: white !important;
    }

    .experience-badge {
      background: #919191 !important;
      color: white;
    }

    .main-badge {
      background: #919191 !important;
    }

    .vertical-timeline-element-date {
      color: white;
    }

    .vertical-timeline-element-icon {
      background: #919191 !important;
    }
    @media only screen and (max-width: 1169px) {
      .vertical-timeline-element-date {
        color: black;
      }
    }
  }

  .modal-inside .modal-content {
    background: #5b5b5b;
    color: white;
  }

  .close-icon {
    color: white;
  }

  .header-icon {
    color: white;
  }

  .animated-background-layer,
  .animated-background-layer-2 {
    display: none;
  }

  #SocalMedia {
    background-color: #202020;
  }
  
  .blade {
    background-color: white;
  }

  .mast {
    background-color: white;
  }

  .drone-body {
    border-color: white;
  }

  .project-container {
    border-color: white;
    border-width: 3px;
    border-style: solid;
  }

  .cloud-icon {
    color: rgb(106, 106, 106);
  }
  
  .additional {
    color: rgb(255, 255, 255);
  }
}